<template>
  <component :is="'style'">{{ css }}</component>
</template>

<script setup lang="ts">
const props = defineProps<{
  mode: Mode;
  customisations: Customisations;
}>();

const {
  brand_color,
  brand_hover_color,
  body_color,
  body_font_type,
  body_websafe_font_family,
  body_font_size,
  collection_title_font_type,
  collection_title_websafe_font_family,
  collection_title_font_size,
  content_title_font_type,
  content_title_websafe_font_family,
  content_title_font_size,
  google_fonts,
  header_overlay_background_style,
  header_overlay_opacity,
  header_message_font_size,
  heading_color,
  link_color,
  link_hover_color,
  header_style,
} = toRefs(props.customisations);

const header_overlay_style = computed(() => {
  const color = header_style.value === "dark" ? "0, 0, 0" : "255, 255, 255";
  const opacity = header_overlay_opacity.value / 100;
  const style = header_overlay_background_style.value;

  if (style === "linear-gradient") {
    return `background-image: linear-gradient(rgba(${color}, 0), rgba(${color}, ${opacity}) 60%)`;
  } else {
    return `background-color: rgba(${color}, ${opacity})`;
  }
});

const bodyUsesGoogleFont = body_font_type.value === "google-font";
const collectionTitleUsesGoogleFont = collection_title_font_type.value === "google-font";
const contentTitleUsesGoogleFont = content_title_font_type.value === "google-font";

const bodyFontFamily = bodyUsesGoogleFont ? google_fonts.value.body?.family : body_websafe_font_family.value;
const collectionTitleFontFamily = collectionTitleUsesGoogleFont ? google_fonts.value.collection_title?.family : collection_title_websafe_font_family.value;
const contentTitleFontFamily = contentTitleUsesGoogleFont ? google_fonts.value.content_title?.family : content_title_websafe_font_family.value;

const css = computed(() => {
  const globalSharedStyles = `
  .ag-block--note-simple2 { background-color: ${brand_color.value}; }
  `;

  const sharedStyles = `
  .ag-theme--classic-grid.ag-collection .ag-header__overlay { ${header_overlay_style.value}; }
  .ag-theme--classic-grid.ag-collection .ag-header .ag-block__button,
  .ag-theme--classic-grid.ag-collection .ag-inspires-header .ag-block__button,
  .ag-theme--classic-grid.ag-collection .ag-main .ag-block__button,
  .ag-theme--classic-grid.ag-collection .ag-main .ag-button.ag-button--color-primary.ag-button--variant-solid { background-color: ${brand_color.value}; color: #fff; }
  .ag-theme--classic-grid.ag-collection .ag-main .ag-button.ag-button--color-primary.ag-button--variant-outline { --tw-ring-color: ${brand_color.value}; color: ${brand_color.value}; }
  .ag-theme--classic-grid.ag-collection .ag-main .ag-block--note-simple2 .ag-button.ag-button--color-primary.ag-button--variant-solid { background-color: #fff; color: ${brand_color.value}; }
  .ag-theme--classic-grid.ag-collection .ag-header .ag-block__button:hover,
  .ag-theme--classic-grid.ag-collection .ag-inspires-header .ag-block__button:hover,
  .ag-theme--classic-grid.ag-collection .ag-main .ag-block__button:hover,
  .ag-theme--classic-grid.ag-collection .ag-main .ag-button.ag-button--color-primary.ag-button--variant-solid:hover { background-color: ${brand_hover_color.value}; color: #fff; }
  .ag-theme--classic-grid.ag-collection .ag-main .ag-button.ag-button--color-primary.ag-button--variant-outline:hover { --tw-ring-color: ${brand_hover_color.value}; background-color: #fff; color: ${brand_hover_color.value}; }
  .ag-theme--classic-grid.ag-collection .ag-main .ag-block--note-simple2 .ag-block__button { background-color: #fff; color: ${brand_color.value}; }
  .ag-theme--classic-grid.ag-collection .ag-main .ag-block--note-simple2 .ag-block__button:hover { background-color: rgba(255, 255, 255, 0.75); color: ${brand_color.value}; }
  .ag-theme--classic-grid.ag-collection .ag-main .ag-block__meta-item .icon { color: ${brand_color.value}; }
  `;

  let globalLandingPageStyles = `
  .ag-collection { font-family: ${bodyFontFamily}; }
  .ag-main { font-size: ${TailwindConfig.fontSize[body_font_size.value].fontSize}; line-height: ${TailwindConfig.fontSize[body_font_size.value].lineHeight}; }
  .ag-collection a:not(.ag-button) { color: ${link_color.value}; }
  .ag-collection a:not(.ag-button):hover { color: ${link_hover_color.value}; }
  .ag-header__title { font-family: ${collectionTitleFontFamily}; font-size: ${
    TailwindConfig.fontSize[collection_title_font_size.value].fontSize
  }; line-height: ${TailwindConfig.fontSize[collection_title_font_size.value].lineHeight}; }
  .ag-header__message { font-size: ${TailwindConfig.fontSize[header_message_font_size.value].fontSize}; line-height: ${
    TailwindConfig.fontSize[header_message_font_size.value].lineHeight
  }; }
  .ag-block__title,
  .ag-block__title a { font-family: ${contentTitleFontFamily}; font-size: ${TailwindConfig.fontSize[content_title_font_size.value].fontSize}; line-height: ${
    TailwindConfig.fontSize[content_title_font_size.value].lineHeight
  }; }
  .ag-block__description { color: ${body_color.value}; }
  .ag-block--heading { font-family: ${collectionTitleFontFamily}; }
  `;

  if (bodyUsesGoogleFont) {
    globalLandingPageStyles += `.ag-collection { font-weight: ${google_fonts.value.body?.weight}; }`;
  }

  if (collectionTitleUsesGoogleFont) {
    globalLandingPageStyles += `.ag-header__title, .ag-collection .ag-block--heading { font-weight: ${google_fonts.value.collection_title?.weight}; }`;
  }

  if (contentTitleUsesGoogleFont) {
    globalLandingPageStyles += `.ag-block__title, .ag-block__title a { font-weight: ${google_fonts.value.content_title?.weight}; }`;
  }

  if (heading_color.value !== "inherit") {
    globalLandingPageStyles += `.ag-header__title { color: ${heading_color.value}; }`;
    globalLandingPageStyles += `.ag-content-subtitle { color: ${heading_color.value}; }`;
    globalLandingPageStyles += `.ag-inspires-header__title { color: ${heading_color.value}; }`;
    globalLandingPageStyles += `.ag-block--heading { color: ${heading_color.value}; }`;
  }

  const landingPageStyles = `
  .ag-theme--classic-grid.ag-collection { border-color: ${brand_color.value}; }
  .ag-theme--classic-grid.ag-collection .ag-contact-bar a { border-color: ${brand_color.value}; color: ${brand_color.value}; }
  .ag-theme--classic-grid.ag-collection .ag-contact-bar a:hover { border-color: ${brand_hover_color.value}; color: ${brand_hover_color.value}; }
  .ag-theme--classic-grid .shared-header__quote { border-color: ${hexToRgbA(brand_color.value, 0.3)}; }
  .ag-theme--classic-grid .shared-header__quote:before { border-top-color: ${hexToRgbA(brand_color.value, 0.3)}; }
  .ag-theme--classic-grid .demo-logo__label { fill: ${brand_color.value}; }
  `;

  const embedStyles = ``;

  return (
    globalSharedStyles +
    sharedStyles +
    (["landing-page", "shared-landing-page"].includes(props.mode) ? globalLandingPageStyles + landingPageStyles : embedStyles)
  );
});
</script>
